import { createMuiTheme, PaletteMode, ThemeProvider } from "@material-ui/core";
import * as React from "react";
import Footer from "./Footer";
import FooterLinks from "./FooterLinks";
import Header from "./Header";

const theme = createMuiTheme({
    palette: {
        mode: "dark" as PaletteMode, 
        primary: {
            main: "#0053C7"
        },
        secondary: {
            main: "#FFF"
        }
    },
});

const Layout = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <div className = "cls_pageWrapper">
                <Header/>
                    <div className = "cls_BodyWrapper">
                        {props.children}
                    </div>
                <FooterLinks/>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default Layout;