import * as React from "react"; 
const containerStyles = require("../styles/layout.module.css")

const Footer = () => {
    const [ isLoggedIn , setLogInStatus] = React.useState("false"); 

    React.useEffect(() => {
        setLogInStatus(localStorage.getItem("isLoggedIn"));
    }, [])

    return (
        <div className = {containerStyles.footerWrapper}>
            {
                // isLoggedIn != "true" && 
                <div className = {containerStyles.cls_footerCont}>       
                    <div className = {containerStyles.cls_footerLeftPanel}>
                        <a href="http://www.mysmitch.com/terms/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' ,  color: "#FFF"}}>
                            <div className = {containerStyles.cls_footerLeftCont}>Terms of Use</div>
                        </a>
                        <a href= "http://www.mysmitch.com/privacy/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' , color: "#FFF" }}>
                            <div className = {containerStyles.cls_footerLeftCont}>Privacy Policy</div>
                        </a>
                    </div>
                    <div className = {containerStyles.cls_footerRightPanel}>
                        IOT Monks Private Limited
                    </div>
                </div> 
            }
            {/* {
                isLoggedIn === "true" && 
                <div className = {containerStyles.cls_myAccFooterWrap}>
                    <div className = {containerStyles.cls_myAccFooterText}>
                        Copyright 2021 IOT Monk Private Limited. All rights reserved.
                    </div>
                </div>
            } */}
        </div>
    )
}

export default Footer;