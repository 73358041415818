import { Link } from "gatsby";
import React, { useEffect, useState } from "react"; 
import { footerLinks } from "../constants/footerLinks";
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import { StaticImage } from "gatsby-plugin-image";

const FooterLinks = () => {
    const data = footerLinks;
    const [ isLoggedIn , setLoggedIn ] = useState("");

    useEffect(() => {
        if( localStorage.getItem('isLoggedIn') != null )
            setLoggedIn(localStorage.getItem('isLoggedIn'))
    }, [])
    
    return (
        <div className = { isLoggedIn === "true" ? "cls_footerLoggedIn" : "cls_footerLinksWrap"} >
            <div className = "cls_footerLinksCont">
                {
                    data.main.map( (item, id) => {
                        if(!(item.header === "Workspace" && isLoggedIn === "true"))
                            return(
                                <div className = "cls_footerLink" key = {id}>
                                    <div className = "cls_footerLinkHeader">{item.header}</div>
                                    <div className = "cls_footerLinkSubWrapper">
                                        {
                                            item.sub.map((sub,idx) => {
                                                return (
                                                    <Link to={sub.link}  style={{ textDecoration: 'none' }} key = {idx}>
                                                        <div className = "cls_footerLinkSubCont" >{sub.title}</div>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                    })
                }
                <div className = "cls_footerLink cls_footerLinkIcons" >
                    <div className = "cls_footerLinkHeader">Find Us</div>
                    <div className = "cls_footerLinkSubWrapper">
                        <a href="https://twitter.com/SmitchDeveloper" target="_blank"  style={{ textDecoration: 'none' }}>
                            <div className = "cls_footerLinkSubCont" >
                                <TwitterIcon />
                            </div>
                        </a>
                        <a href="https://www.facebook.com/smitchdeveloper/" target="_blank"  style={{ textDecoration: 'none' }}>
                            <div className = "cls_footerLinkSubCont" >
                                <FacebookIcon />
                            </div>
                        </a>
                        <a href="https://discord.gg/pA2EAEPfj2" target="_blank"  style={{ textDecoration: 'none' }}>
                            <div className = "cls_footerLinkSubCont" >
                                <StaticImage className = "cls_HomeBgLine" src="../images/discord.png" alt="Smitch" width={24}/>
                            </div>
                        </a>
                    </div>
                    </div>
            </div>
        </div>
    )
}

export default FooterLinks;