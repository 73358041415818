// extracted by mini-css-extract-plugin
export const container = "layout-module--container--nBLtT";
export const logoWrapper = "layout-module--logoWrapper--1_1Dl";
export const logoText = "layout-module--logoText--1RRn8";
export const headerTabsWrapper = "layout-module--headerTabsWrapper--1qKUG";
export const headerTabs = "layout-module--headerTabs--gDGg7";
export const accountHeaderText = "layout-module--accountHeaderText--3PpTk";
export const paperMenuWrapper = "layout-module--paperMenuWrapper--1SLKT";
export const workspacePaperMenuWrapper = "layout-module--workspacePaperMenuWrapper--2Yxb_";
export const footerWrapper = "layout-module--footerWrapper--27jz3";
export const signupHeaderText = "layout-module--signupHeaderText--2kglH";
export const loginHeaderText = "layout-module--loginHeaderText--1jSLJ";
export const cls_footerCont = "layout-module--cls_footerCont--1YceX";
export const cls_footerLeftPanel = "layout-module--cls_footerLeftPanel--12_cZ";
export const cls_footerLeftCont = "layout-module--cls_footerLeftCont--1Q3Sv";
export const cls_footerRightPanel = "layout-module--cls_footerRightPanel--2QdBN";
export const headermyAccTabWrapper = "layout-module--headermyAccTabWrapper--1pVE3";
export const headermyAccTabs = "layout-module--headermyAccTabs--1aYAK";
export const headermyAccTabCont = "layout-module--headermyAccTabCont--1Lw7N";
export const cls_headerProfileApp = "layout-module--cls_headerProfileApp--1foOx";
export const cls_headerProfileImg = "layout-module--cls_headerProfileImg--2yUFX";
export const cls_headerProfileName = "layout-module--cls_headerProfileName--3kRM6";
export const cls_headerProfileEmail = "layout-module--cls_headerProfileEmail--IjY4q";
export const cls_HeaderLogoutBtn = "layout-module--cls_HeaderLogoutBtn--1-4NE";
export const accSettingsHeaderText = "layout-module--accSettingsHeaderText--X_L7c";
export const cls_LogoutBtnWrap = "layout-module--cls_LogoutBtnWrap--1_AUH";
export const cls_myAccFooterText = "layout-module--cls_myAccFooterText--W2GOh";
export const accHeaderItemWrap = "layout-module--accHeaderItemWrap--2pW53";
export const accHeaderItemIcon = "layout-module--accHeaderItemIcon--kYqnl";
export const cls_headerWorkSPaceTitle = "layout-module--cls_headerWorkSPaceTitle--1rH2J";
export const cls_overviewTitle = "layout-module--cls_overviewTitle--1j42D";
export const cls_appCreateBtn = "layout-module--cls_appCreateBtn--lwRKk";
export const cls_appCancelBtn = "layout-module--cls_appCancelBtn--1oFrj";