export const footerLinks = {
    "main" : [{
        "header": "Documentation", 
        "sub" : [
            {
                "title" : "Getting Started", 
                "link" : "/"
            }
        ]
    }, 
    {
        "header": "Workspace", 
        "sub" : [
            {
                "title" : "Login to your workspace", 
                "link" : "/login"
            }, 
            {
                "title" : "Signup for your workspace", 
                "link" : "/signup"
            }
        ]
    }, {
        "header": "Resources", 
        "sub" : [
            {
                "title" : "API Reference Swagger", 
                "link" : "https://app.api.developer.mysmitch.com/docs"
            }, 
            {
                "title" : "API Reference Redoc", 
                "link" : "https://app.api.developer.mysmitch.com/redoc"
            }, 
            {
                "title" : "GitHub", 
                "link" : "https://github.com/mysmitch"
            }
        ]
    }, {
        "header": "Support", 
        "sub" : [
            {
                "title" : "FAQs", 
                "link" : "/"
            }, 
            {
                "title" : "API Status", 
                "link" : "https://statuspage.freshping.io/53235-SmitchDeveloperPortal"
            }
        ]
    }]
}