export const API_URL =
  //   process.env.REACT_APP_API_URL ||
  // "https://admin-server.dev-developer-portal.mysmitch.com/";
  "https://admin.api.developer.mysmitch.com/";

export const GITHUB_CLIENT_ID =
  //   process.env.REACT_APP_API_URL ||
    "a4d77aa44346a2782f68";

export const GAPI_CLIENT_ID =
  //   process.env.REACT_APP_API_URL ||
  // "486584977143-7puiucjb443991ktlhhsqjhmfjcb0tdp.apps.googleusercontent.com";
    "932486897995-r777pu8heha8511am15fd7ppaclg4g7h.apps.googleusercontent.com";
