import * as React from "react"; 
const containerStyles = require("../styles/layout.module.css")
import { Link } from "gatsby"
import { ClickAwayListener, MenuItem, MenuList, Paper , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { API_URL } from "../constants/constants";
import { toast , ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { requestHandler } from "../utils/utils";
import { AccountCircle, ArrowDropDown, GitHub, HelpOutline, Person } from "@material-ui/icons";
import { navigate } from 'gatsby';
import MenuIcon from '@material-ui/icons/Menu';
import AppsIcon from '@material-ui/icons/Apps';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import { useLocation } from "@reach/router";
import TeamContext from "../context/TeamContextProvider";

const Header = () => {
    const [ isLoggedIn , setLogInStatus] = React.useState("false");
    const [open, setOpen] = React.useState(false);
    const [ workspaceOpen, setWorkspaceOpen ] = React.useState(false);
    const title = isLoggedIn === "true" ? "Developer Workspace" : "SMITCH for Developers"; 
    const [ teams , setTeams ] = React.useState([]);
    const [ switchTeam , showSwitchTeam ] = React.useState(false);
    const [ userName , setUserName ] = React.useState("")
    const [ userEmail , setEmail ] = React.useState("");
    const [ teamName , setTeamName ] = React.useState("");
    const [ currTeamID , setCurrTeamID ] = React.useState("");
    const { userData , setUserData } = React.useContext(TeamContext)
    const location:any= useLocation();

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
            setWorkspaceOpen(false);
        }
    }

    const switchWorkspace = () => {
        callProfileAPI();
        showSwitchTeam(true);
    }

    const callProfileAPI = async () => {
        try {
            const result:any = await requestHandler(API_URL + "developer/" , null, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "GET")
            if(result.status === "success") 
            {
                setTeams(result.data.teams);
            }
            if(result.status === "failed") 
            {
            }      
        }
        catch (error) {
            // Sentry.captureException(error);
            // toast.error(error.response.data.error); 
            console.log("error", error);
        }
    } 

    React.useEffect(() => {
        setLogInStatus(localStorage.getItem("isLoggedIn"));
        if(userData != null && userData != "") {
            const userDataTemp = JSON.parse(userData)
            if(isLoggedIn === "true" && userDataTemp != null) {
                setUserName((userDataTemp.name ? userDataTemp.name : ""))
                setEmail((userDataTemp.email ? userDataTemp.email : ""))
                setTeamName((userDataTemp.teamName ? userDataTemp.teamName : ""))
                setCurrTeamID((userDataTemp.team_id ? userDataTemp.team_id : ""))
            }
        }
    }, [isLoggedIn , userData])

    const onLogout = async () => {
        try {
            const result:any = await requestHandler(API_URL + "developer/logout" , null, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "POST")
            if(result.status === "success") 
            {
                toast.success(result.message); 
                localStorage.setItem("isLoggedIn", "false");
                setLogInStatus("false");
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                localStorage.setItem("userData", "");
                navigate("/");
            }
            if(result.status === "failed") 
            {
                toast.error(result.errorMessage); 
            }      
        }
        catch (error) {
            // Sentry.captureException(error);
            toast.error(error.errorMessage); 
            console.log("error", error);
        }
    }

    const handleClose = (event) => {
        if(!event.target.matches(".menuList, .menuListItem , .paperMenuWrapper , .workspacePaperMenuWrapper"))
            setOpen(false);
            setWorkspaceOpen(false);
    };

    const closeSwitchTeam = () => {
        showSwitchTeam(false);
    };

    const onRowClick = (team) => {
        setCurrTeamID(team.team_id); 
        setTeamName(team.name)
    }

    const changeTeam = () => {
        let temp = JSON.parse(localStorage.getItem("userData"));
        temp.team_id = currTeamID;
        temp.teamName = teamName;
        setUserData(JSON.stringify(temp));
        if(location.pathname === "/user/apps")
            console.log("");
        else
            navigate("/user/apps");
        closeSwitchTeam()
            
    }

    return (
        <>
        <ToastContainer />
        <div className = {containerStyles.container}>    
                <div className = {containerStyles.logoWrapper}>
                    <Link to = "/"  style={{ textDecoration: 'none' , width: "100%"}} className = {containerStyles.logoWrapper}>
                        <img
                        src="https://d199xmsg3owom4.cloudfront.net/images/smitch_logo_white.png"
                        alt="SMITCH logo" height="40" width="40" />
                        <span className = {containerStyles.logoText}>
                            {title}
                            <span className = {containerStyles.logoSubText}>.Beta</span>
                        </span>
                    </Link>
                </div>
            {
                ( isLoggedIn === "false" || isLoggedIn === null ) && 
                <>
                    <div className = {containerStyles.headerTabsWrapper}>
                        <a href = "https://docs.developer.mysmitch.com/" target = "_blank" style = {{ textDecoration: 'none' , color: "#FFF"}}>
                            <div className = {containerStyles.headerTabs} id = {containerStyles.headerAppStore}>Docs</div>
                        </a>
                        {/* <div className = {containerStyles.headerTabs} id = {containerStyles.headerCommunity}>Support</div> */}
                        <a href = "https://app.api.developer.mysmitch.com/docs" target = "_blank" style={{ textDecoration: 'none' , color: "#FFF"}}>
                            <div className = {containerStyles.headerTabs} id = {containerStyles.headerRef}>API Reference</div>
                        </a>
                        <div className = {containerStyles.headerTabs} id = {containerStyles.headerDocs}>Blog</div>
                        <a href = "https://github.com/mysmitch " target = "_blank" style={{ textDecoration: 'none' , color: "#FFF"}}>
                            <div className = {containerStyles.headerTabs} id = {containerStyles.headerDocs}>
                                <GitHub />
                            </div>
                        </a>
                    </div>
                    <Link to="/login"  style={{ textDecoration: 'none' }}>
                        <div className = {containerStyles.loginHeaderText}>Sign In</div>
                    </Link>
                </>
            }
            {
                isLoggedIn === "true" && 
                <>
                    <div className = {containerStyles.headermyAccTabWrapper}>
                        <div className = {containerStyles.headermyAccTabs}>
                            <a href = "https://docs.developer.mysmitch.com" target = "_blank" style = {{ textDecoration: 'none' , color: "#FFF"}}>
                                <div className = {containerStyles.headermyAccTabCont}>Docs</div>
                            </a>
                            <a href = "https://app.api.developer.mysmitch.com/docs" target = "_blank" style={{ textDecoration: 'none' , color: "#FFF"}}>
                                <div className = {containerStyles.headermyAccTabCont}>API Reference</div>
                            </a>
                            <div className = {containerStyles.headermyAccTabCont}>
                                {/* <HelpOutline /> */}
                                <GitHub />
                            </div>
                            {/* <Link to="/"  style={{ textDecoration: 'none' }}> */}
                            <div className = {containerStyles.loginHeaderText} onClick = {() => { setOpen(false); setWorkspaceOpen(true)}}>
                                {teamName} <ArrowDropDown /> 
                            </div>
                            {/* </Link> */}
                            <div className = {containerStyles.headermyAccTabContDD} onClick = {() => { setOpen(true); setWorkspaceOpen(false)}}>
                                <Person />
                                <ArrowDropDown />
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                open &&  
                <Paper className = {containerStyles.paperMenuWrapper}>
                    <ClickAwayListener onClickAway={handleClose}>
                    <MenuList className = {containerStyles.menuList} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem className = {containerStyles.menuListItem} onClick={handleClose}>
                            <div className = {containerStyles.cls_headerProfileApp}>
                                <div className = {containerStyles.cls_headerProfileImg}>
                                    <AccountCircle />
                                </div>
                                <div className = {containerStyles.cls_headerProfileDet}>
                                    <div className = {containerStyles.cls_headerProfileName}>{userName}</div>
                                    <div className = {containerStyles.cls_headerProfileEmail}>{userEmail}</div>
                                </div>
                            </div>
                        </MenuItem>
                        <MenuItem className = {containerStyles.menuListItem} onClick={handleClose}>
                            <Link to="/user/userSettings"  style={{ textDecoration: 'none' , width: "100%"}}>
                                <div className = {containerStyles.accHeaderItemWrap}>
                                    <MenuIcon className = {containerStyles.accHeaderItemIcon}/>
                                    <div className = {containerStyles.accSettingsHeaderText}>Manage Account</div>
                                </div>
                            </Link> 
                        </MenuItem>
                        <MenuItem className = {containerStyles.menuListItem} onClick={handleClose}>
                            <div className = {containerStyles.cls_LogoutBtnWrap}>
                                <div className = {containerStyles.cls_HeaderLogoutBtn} onClick={() => onLogout()}>Logout</div> 
                            </div>
                        </MenuItem>
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
            }
            {
                workspaceOpen &&  
                <Paper className = {containerStyles.workspacePaperMenuWrapper}>
                    <ClickAwayListener onClickAway={handleClose}>
                    <MenuList className = {containerStyles.menuList} autoFocusItem={open} id="menu-list-workspace" onKeyDown={handleListKeyDown}>
                        <MenuItem className = {containerStyles.switchWorkspceItem} onClick={handleClose}>
                            <div className = {containerStyles.cls_headerWorkSPaceTitle} onClick = {switchWorkspace}>
                                Switch Team
                            </div>
                        </MenuItem>
                        <MenuItem className = {containerStyles.menuListItem} onClick={handleClose}>
                            <Link to={`/user/apps`}  style={{ textDecoration: 'none' , width: "100%"}}>
                                <div className = {containerStyles.accHeaderItemWrap}>
                                    <AppsIcon className = {containerStyles.accHeaderItemIcon}/>
                                    <div className = {containerStyles.accSettingsHeaderText}>Apps</div>
                                </div>
                            </Link> 
                        </MenuItem>
                        <MenuItem className = {containerStyles.menuListItem} onClick={handleClose}>
                            <Link to="/user/apps?tab=1" style={{ textDecoration: 'none' , width: "100%"}}>
                                <div className = {containerStyles.accHeaderItemWrap}>
                                    <PeopleAltIcon className = {containerStyles.accHeaderItemIcon}/>
                                    <div className = {containerStyles.accSettingsHeaderText}>Users</div>
                                </div>
                            </Link> 
                        </MenuItem>
                        <MenuItem className = {containerStyles.menuListItem} onClick={handleClose}>
                            <Link to="/user/apps?tab=2" style={{ textDecoration: 'none' , width: "100%"}}>
                                <div className = {containerStyles.accHeaderItemWrap}>
                                    <SettingsIcon className = {containerStyles.accHeaderItemIcon}/>
                                    <div className = {containerStyles.accSettingsHeaderText}>Settings</div>
                                </div>
                            </Link> 
                        </MenuItem>
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
            }
        </div>
        {
            <Dialog open={switchTeam} onClose={closeSwitchTeam} aria-labelledby="form-dialog-title" className = "cls_apikeyPopup" fullWidth>
                <DialogTitle id="form-dialog-title" className = {containerStyles.cls_overviewTitle}>Switch Team</DialogTitle>
                    <DialogContent>
                        <DialogContentText className = {containerStyles.apiKeyText}>
                            Choose your Team
                        </DialogContentText>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">ID</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {teams.map((row) => 
                                (
                                    <TableRow key={row.team.team_id} className = { currTeamID === row.team.team_id ? "activeWorkspace": "inactiveWorkspace"} onClick = {() => onRowClick(row.team)}>
                                    <TableCell component="th" scope="row">
                                        {row.team.name}
                                    </TableCell>
                                    <TableCell align="right">{row.team.team_id}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions className = {containerStyles.cls_apiBtnWrapper}>
                        <div className = {containerStyles.cls_appCancelBtn} onClick={closeSwitchTeam} color="primary">
                            Cancel
                        </div>
                        <div className = {containerStyles.cls_appCreateBtn} onClick = {changeTeam} color="primary">
                            Open
                        </div>
                    </DialogActions>
            </Dialog>
        }
        </>
    )
}

export default Header;